import React from 'react';
import { withStyles } from '@material-ui/core/styles';

var environment = '';

const styles = (theme) => ({
    wrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: 0,
        borderBottom: '48px solid transparent',
        borderLeft: '48px solid ' + theme.light.error,
        zIndex: 1200,
        pointerEvents: 'none',
        '@media print': {
            display: 'none'
        }
    },
    title: {
        position: 'fixed',
        color: theme.light.text.inverted,
        fontSize: '1.2rem',
        transform: 'rotate(-45deg)',
        textAlign: 'center',
        top: 6,
        left: -6,
        width: 48
    }
});

/**
 * @description DrcEnvironmentMarker is an environment specific marker
 * @class DrcEnvironmentMarker
 * @property {object} classes Sets the style of marker
 * @example
 * <DrcEnvironmentMarker />
 * @donotprint true
 * @category Environment
 * @tags environment marker notice alert warning
 * @extends {React.Component}
 */

class DrcEnvironmentMarker extends React.Component {
    /**
     * Creates an instance of DrcEnvironmentMarker.
     * @param {*} props
     * @memberof DrcEnvironmentMarker
     */

    constructor(props) {
        super(props);

        var hostname = window.location.hostname.toLowerCase();

        if (hostname.includes('dev') || hostname.includes('localhost')) {
            environment = 'Dev';
        } else if (hostname.includes('stage')) {
            environment = 'Stage';
        } else if (hostname.includes('uat')) {
            environment = 'UAT';
        } else if (hostname.includes('qa')) {
            environment = 'QA';
        } else if (hostname.includes('tst') || hostname.includes('test')) {
            environment = 'Test';
        } else {
            environment = ''; //Production, do not show to user
        }
    }

    /**
     * @return {*}
     * @memberof DrcEnvironmentMarker
     */

    render() {
        const { classes } = this.props;
        if (environment.length === 0) {
            return null;
        }

        return (
            <div className={classes.wrapper}>
                <span className={classes.title}>{environment}</span>
            </div>
        );
    }
}

export default withStyles(styles)(DrcEnvironmentMarker);
