import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DrcLoading from './DrcLoading';

/**
 * @ignore
 * @memberof DrcBackdrop
 */
const styles = (theme) => ({
    root: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: theme.light.primary,
        backgroundColor: theme.light.transparent,
        display: 'none',
        zIndex: 1100,
        ['@media (prefers-color-scheme: dark)']: {
            color: theme.dark.text.primary,
            backgroundColor: '#111C'
        }
    },
    rootShow: {
        display: 'block'
    },
    rootLoading: {
        zIndex: 1301
    },
    loadingDiv: {
        width: '244px',
        left: '50vw',
        left: 'calc(50vw - 122px)',
        top: '50vh',
        top: 'calc(50vh - 60px)',
        position: 'fixed',
        padding: '20px',
        backgroundColor: theme.light.variant.secondary,
        borderRadius: '10px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: theme.dark.body
        }
    }
});

/**
 * @description DrcBackdrop is a background that appears when the the app is loading something.
 * @class DrcBackdrop
 * @property {array} children list of children.
 * @property {string} className className for the backdrop.
 * @property {boolean} isLoading shows or hides the loading message.
 * @property {string} loadingMessage loading message.
 * @property {boolean} show shows or hide the backdrop.
 * @property {function} onClick event when backdrop is clicked.
 * @property {object} classes customized style for backdrop.
 * @example <DrcBackdrop show={true} isLoading={true} onClick={() => {}} loadingMessage='loading example' />
 * @extends {React.Component}
 * @category Loading
 * @tags loading backdrop blocking
 */
class DrcBackdrop extends React.Component {
    /**
     * @description render method for the DrcBackdrop, part of the react component lifecyle.
     * @return {*} the view for this component.
     * @memberof DrcBackdrop
     */
    render() {
        const { children, className, isLoading, loadingMessage, show, onClick, classes } = this.props;
        var clickEvent = onClick || (() => {});
        return (
            <div className={`${classes.root} ${show ? classes.rootShow : ''} ${isLoading ? classes.rootLoading : ''} ${className}`} onClick={clickEvent}>
                {children}
                {isLoading ? (
                    <div className={classes.loadingDiv}>
                        <DrcLoading text={loadingMessage ? loadingMessage : 'Loading'} />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withStyles(styles)(DrcBackdrop);
