import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DrcTooltip from './DrcTooltip';
import DrcLinkItem from './DrcLinkItem';

/**
 *@ignore
 */
const styles = (theme) => ({
    card: {
        '--s': '106px' /* size */,
        '--m': '1px' /* margin */,
        width: 'var(--s)',
        margin: 'var(--m)',
        height: 'calc(var(--s)*1.1547)',
        lineHeight: 'calc(var(--s)*1.1547)',
        position: 'relative',
        display: 'inline-block',
        fontSize: 'initial',
        clipPath: 'polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%)',
        background: 'radial-gradient(#ddd 26%, #eee 81%, #FFF 100%)',
        marginBottom: 'calc(var(--m) - var(--s)*0.2885)',
        '-webkit-print-color-adjust': 'exact',
        '@media (prefers-color-scheme: dark)': {
            background: 'radial-gradient(#222 50%, #111 100%)'
        }
    },
    cardLabel: {
        textAlign: 'center',
        fontWeight: 'bold',
        cursor: 'help',
        width: '100%',
        padding: '0 3px',
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: 'normal'
    },
    linkText: {
        textAlign: 'center !important',
        minWidth: 0
    }
});

/**
 * @description DrcHexCard is a styled component which has hexagon shape and we can be used to display text.
 * @class DrcHexCard
 * @example <DrcHexCard tooltip description={'LOW Impact'} icon={<ArrowUpwardIcon className={`${classes.appIcon}
 * ${classes.highIcon}`} />}>
 * {'Application Delivery'}
 * </DrcHexCard>
 * 
 * <DrcHexCard
    linkKey={'Title'}
    link={'https://www.driscolls.com/'}
    title={'Driscolls'}
    href
    icon={<ArrowUpwardIcon className={`${classes.appIcon} ${classes.highIcon}`} />}
>
    {'Driscolls'}
</DrcHexCard>

 * @property {string} width width of card
 * @property {Object} style style of card
 * @property {children} children will be shown as children
 * @property {string} backgroundColor backgroundColor of the card
 * @property {Object} classes custom style classes
 * @property {boolean} tooltip if true tooltip is enabled
 * @property {string} toolTipText text to be displayed on the tooltip 
 * @property {string} title title of the link
 * @property {string} link href or url
 * @property {boolean} href if true link is taken as href else link is taken as url
 * @extends {React.Component}
 */
class DrcHexCard extends React.Component {
    /**
     *
     *
     * @return {*}
     * @memberof DrcHexCard
     */
    render() {
        const { classes, width, style, children, backgroundColor, id, className, icon, tooltip, title, link, href, toolTipText } = this.props;
        let urlType = href
            ? {
                  href: link
              }
            : { url: link };

        return (
            <div
                id={id}
                className={`${classes.card} ${className}`}
                style={{
                    width: width,
                    background: backgroundColor,
                    ...style
                }}
            >
                {icon ? icon : ''}
                {tooltip ? (
                    <DrcTooltip tipText={toolTipText}>
                        <span className={classes.cardLabel}>{children}</span>
                    </DrcTooltip>
                ) : (
                    ''
                )}
                {link ? <span className={classes.cardLabel}>{<DrcLinkItem link={urlType} title={title} toggleDrawer={() => {}} />}</span> : ''}
            </div>
        );
    }
}

export default withStyles(styles)(DrcHexCard);
