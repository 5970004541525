import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

/**
 *@ignore
 */
const styles = (theme) => ({
    list: {
        listStyle: 'none',
        overflow: 'hidden',
        font: '18px Sans-Serif',
        paddingLeft: '0',
        display: 'inline-block'
    },
    item: {
        float: 'left',
        '&:first-child a': {
            paddingLeft: '10px',
            borderRadius: '5px 0 0 5px'
        },
        '&:nth-child(2) a': {
            background: theme.light.accent.brightness(-5),
            '@media (prefers-color-scheme: dark)': {
                background: theme.dark.accent.brightness(-5)
            }
        },
        '&:nth-child(2) a:after': {
            borderLeftColor: theme.light.accent.brightness(-5),
            '@media (prefers-color-scheme: dark)': {
                borderLeftColor: theme.dark.accent.brightness(-5)
            }
        },
        '&:nth-child(3) a': {
            background: theme.light.accent.brightness(0),
            '@media (prefers-color-scheme: dark)': {
                background: theme.dark.accent.brightness(0)
            }
        },
        '&:nth-child(3) a:after': {
            borderLeftColor: theme.light.accent.brightness(0),
            '@media (prefers-color-scheme: dark)': {
                borderLeftColor: theme.dark.accent.brightness(0)
            }
        },
        '&:nth-child(4) a': {
            background: theme.light.accent.brightness(5),
            '@media (prefers-color-scheme: dark)': {
                background: theme.dark.accent.brightness(5)
            }
        },
        '&:nth-child(4) a:after': {
            borderLeftColor: theme.light.accent.brightness(5),
            '@media (prefers-color-scheme: dark)': {
                borderLeftColor: theme.dark.accent.brightness(5)
            }
        },
        '&:nth-child(5) a': {
            background: theme.light.accent.brightness(10),
            '@media (prefers-color-scheme: dark)': {
                background: theme.dark.accent.brightness(10)
            }
        },
        '&:nth-child(5) a:after': {
            borderLeftColor: theme.light.accent.brightness(10),
            '@media (prefers-color-scheme: dark)': {
                borderLeftColor: theme.dark.accent.brightness(10)
            }
        },
        '&:last-child a': {
            background: theme.light.primary + ' !important',
            color: theme.light.text.primary + ' !important',
            pointerEvents: 'none',
            cursor: 'default',
            paddingRight: '10px',
            border: '1px solid ' + theme.light.border.middle,
            borderRadius: '5px',
            lineHeight: '14px',
            '@media (prefers-color-scheme: dark)': {
                background: theme.dark.primary + ' !important',
                color: theme.dark.text.primary + ' !important',
                border: '1px solid ' + theme.dark.border.middle
            }
        },
        '&:last-child a::after': {
            border: '0'
        }
    },
    link: {
        color: theme.light.text.inverted + theme.important,
        textDecoration: 'none',
        padding: '10px 0 10px 45px',
        paddingLeft: '30px',
        background: theme.light.accent.brightness(-10),
        position: 'relative',
        display: 'block',
        float: 'left',
        '&::after': {
            content: '" "',
            display: 'block',
            width: '0',
            height: '0',
            borderTop: '30px solid transparent',
            borderBottom: '30px solid transparent',
            borderLeft: '20px solid ' + theme.light.accent.brightness(-10),
            position: 'absolute',
            top: '50%',
            marginTop: '-30px',
            left: '100%',
            zIndex: '2'
        },
        '&::before': {
            content: '" "',
            display: 'block',
            width: '0',
            height: '0',
            borderTop: '50px solid transparent',
            borderBottom: '50px solid transparent',
            position: 'absolute',
            top: '50%',
            marginTop: '-50px',
            marginLeft: '1px',
            left: '100%',
            zIndex: '1'
        },
        '&:hover': {
            background: theme.light.accent.brightness(-15) + ' !important'
        },
        '&:hover:after': {
            borderLeftColor: theme.light.accent.brightness(-15) + ' !important'
        }
    }
});

const MARGIN_OFFSET = 30;

var parentSize = 0;

/**
 * @description Will create an unordered list of string representations of each link location with updated sizes according to parent container width.
 * @property {Array} children Array of link objects. Each Link Object has href and title values.
 * @property {Object} style Customized style for each link as list item.
 * @class DrcBreadcrumb
 * @example
 * const arrLinks = [{href: "", title: "title 1"}, {href: "", title: "title 2"}]
 * <DrcBreadcrumb>{arrLinks}</DrcBreadcrumb>
 * @category Input
 * @tags link button history list
 * @extends {React.Component}
 */
class DrcBreadcrumb extends React.Component {
    /**
     * Creates an instance of DrcBreadcrumb.
     * @param {*} props
     * @memberof DrcBreadcrumb
     */
    constructor(props) {
        super(props);

        this.state = {
            links: []
        };

        this.breadcrumb = React.createRef();

        this.updateBreadcrumbs = this.updateBreadcrumbs.bind(this);
        this.compareLink = this.compareLink.bind(this);
        this.calculateSize = this.calculateSize.bind(this);
        this.setLinks = this.setLinks.bind(this);
    }

    /**
     * @description will calculate link size, size of the parent container element, and accordingly set state of links array with updated sizes.
     * @memberof DrcBreadcrumb
     */
    updateBreadcrumbs() {
        var links = this.props.children || [];
        var fullControlWidth = this.calculateSize(links) || 0;
        var parentWidth = this.breadcrumb.current.parentElement.offsetWidth || 0;

        if (parentWidth - MARGIN_OFFSET <= fullControlWidth) {
            if (parentSize != parentWidth) {
                this.setLinks(links, fullControlWidth, parentWidth);
            }

            return;
        }

        //Something obviously changed so update
        if (links.length !== this.state.links.length || (this.state.links.length > 2 ? this.state.links[1].title === '…' : false)) {
            this.setLinks(links, fullControlWidth, parentWidth);
            return;
        }
    }

    /**
     * @description calculates the width of the links
     * @param {Array} links all links in an array
     * @returns {Number} width number for the links
     * @memberof DrcBreadcrumb
     */
    calculateSize(links) {
        if (links.length <= 0) {
            return 0;
        }

        return links.length * 42 + links.map((link) => link.title.length * 6).reduce((a, b) => a + b);
    }

    /**
     * @description set the state of links array depending upon parent element width
     * @param {Array} propLinks
     * @param {Number} fullControlWidth width of the links container
     * @param {Number} parentWidth parent container width
     * @memberof DrcBreadcrumb
     */
    setLinks(propLinks, fullControlWidth, parentWidth) {
        var links = [...propLinks];
        var parentIsSmall = parentWidth - MARGIN_OFFSET <= fullControlWidth;
        parentSize = parentWidth;

        if (links.length > 2 && parentIsSmall) {
            var href = '';

            while (links.length > 2) {
                href = links[1].href;
                links.splice(1, 1);

                //39 for extra dot dot dot
                if (this.calculateSize(links) + 39 < parentWidth - MARGIN_OFFSET) {
                    break;
                }
            }

            links.splice(1, 0, { href, title: '…' });

            if (this.state.links.length === links.length) {
                return;
            }
        }

        this.setState({ links });
    }

    /**
     * @description checkes if the two links are same or not
     * @param {*} link1
     * @param {*} link2
     * @returns {Boolean}
     * @memberof DrcBreadcrumb
     */
    compareLink(link1, link2) {
        if (link1.href === link2.href && link1.title === link2.title) {
            return true;
        }

        return false;
    }

    /**
     *@ignore
     */
    componentDidMount() {
        this.updateBreadcrumbs();
        window.addEventListener('resize', this.updateBreadcrumbs.bind(this));
    }

    /**
     *@ignore
     */
    componentDidUpdate() {
        this.updateBreadcrumbs();
    }

    /**
     *@ignore
     */
    componentWillUnmount() {
        this.setState({ links: [], parentIsSmall: false });
        window.removeEventListener('resize', this.updateBreadcrumbs.bind(this));
    }

    /**
     *
     * @returns {*}
     * @memberof DrcBreadcrumb
     */
    render() {
        const { classes } = this.props;

        return (
            <ul ref={this.breadcrumb} className={classes.list} style={this.props.style}>
                {this.state.links.map((link) => (
                    <li key={link.href} className={classes.item}>
                        <Link className={classes.link} to={link.href}>
                            {link.title}
                        </Link>
                    </li>
                ))}
            </ul>
        );
    }
}

export default withStyles(styles)(DrcBreadcrumb);
