import React from 'react';
import { withStyles } from '@material-ui/core/styles';

/**
 *@ignore
 */
const styles = (theme) => ({
    root: {
        maxWidth: '100%',
        marginTop: '15px',
        width: '500px',
        paddingBottom: '5px',
        marginBottom: '15px',
        marginLeft: '0',
        fontSize: '1.5rem',
        borderBottom: 'solid 1px ' + theme.light.accent.text.primary,
        color: theme.light.accent.text.primary,
        fontWeight: '500',
        [theme.darkTheme]: {
            borderBottom: 'solid 1px ' + theme.dark.accent.text.primary,
            color: theme.dark.accent.text.primary
        }
    },
    indent: {
        width: '485px',
        paddingBottom: '3px',
        marginBottom: '10px',
        marginLeft: '15px',
        fontSize: '1.25rem'
    },
    fullWidth: {
        width: '100%'
    }
});

/**
 * @description is a styled header for adding header text
 * @class DrcLegend
 * @example <DrcLegend>{'Driscolls'}</DrcLegend>
 * @category Layout
 * @tags layout text title legend
 * @property {Object} classes custom styling classes
 * @property {Object} style custom styles
 * @property {children } children will be shown as children
 */
class DrcLegend extends React.Component {
    /**
     *
     *
     * @return {*}
     * @memberof DrcLegend
     */
    render() {
        const { style, children, className, indent, fullWidth, classes } = this.props;

        return (
            <legend className={`${classes.root} ${indent ? classes.indent : ''} ${fullWidth ? classes.fullWidth : ''} ${className ? className : null}`} style={{ ...style }}>
                {children}
            </legend>
        );
    }
}

export default withStyles(styles)(DrcLegend);
