import React from 'react';
import { Prompt } from 'react-router-dom';
import DrcButton from './DrcButton';

/**
 * @description Provides a prompt window to the user before navigating away from the form page submit.
 * @property {Boolean} isBlocking flag to prevent or allow navigation accordingly
 * @property {*} children input elements for the form.
 * @property {*} submitHandler function callback to handle form submit
 * @property {Object} buttonProps props to manage submit button using DrcButton
 *  @example
 * const userName=""
 * <DrcFormBlocker isBlocking={true} submitHandler={()=>{}} buttonProps={isSecondary}>
 *      <DrcInput label={'User Name'} value={userName} required />
 * </DrcFormBlocker>
 * @donotprint true
 * @category Input
 * @tags input form block stop submit
 * @class DrcFormBlocker
 * @extends {React.Component}
 */
class DrcFormBlocker extends React.Component {
    /**
     * Creates an instance of DrcFormBlocker.
     * @param {*} props
     * @memberof DrcFormBlocker
     */
    constructor(props) {
        super(props);

        this.state = {
            isBlocking: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     *@ignore
     */
    handleSubmit() {}

    /**
     * @returns {*}
     * @memberof DrcFormBlocker
     */
    render() {
        const { isBlocking, submitHandler, buttonProps, children } = this.props;

        return (
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    event.target.reset();
                    this.setState({
                        isBlocking: false
                    });
                }}
            >
                {children}
                <Prompt when={isBlocking} message={(location) => `Are you sure you want to go to ${location.pathname}`} />
                <p>Blocking? {isBlocking ? 'Yes, click a link or the back button' : 'Nope'}</p>

                <p>
                    <DrcButton {...buttonProps} onClick={submitHandler}>
                        Submit
                    </DrcButton>
                </p>
            </form>
        );
    }
}

export default DrcFormBlocker;
