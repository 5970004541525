import React from 'react';
import { withStyles } from '@material-ui/core/styles';

/**
 *@ignore
 */
const styles = (theme) => ({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            fontFamily: (props) => props.fontFamily || theme.typography.fontFamily,
            fontSize: '12px',
            outline: 'none !important'
        },
        'a, a:visited': {
            color: theme.light.text.primary,
            '@media (prefers-color-scheme: dark)': {
                color: theme.dark.text.primary
            }
        },
        'a:focus, a:hover, button:focus, button:hover': {
            outline: 'none',
            borderColor: 'inherit'
        },
        body: {
            backgroundColor: theme.light.body,
            color: theme.light.text.primary,
            margin: '0',
            padding: '0',
            '@media (prefers-color-scheme: dark)': {
                backgroundColor: theme.dark.body,
                color: theme.dark.text.primary
            }
        },
        h1: {
            fontSize: '2em'
        }
    }
});
/**
 * @description The DrcGlobalStyle is to apply styles globally in the Driscolls format and is customizable
 * @class DrcGlobalStyle
 * @example
 * <DrcGlobalStyle fontFamily={'cursive'}><h1>This is a header</h1><div><p>simple text</p></div></DrcGlobalStyle>
 * @donotprint true
 * @category Layout
 * @tags layout global styles important colors pretty
 * @property {string} fontFamily font family style
 * @property {*} children component wrapped by DrcGlobalStyle
 * @extends {React.Component} */
class DrcGlobalStyle extends React.Component {
    /**
     *
     *
     * @return {*}
     * @memberof DrcGlobalStyle
     */
    render() {
        const { children } = this.props;
        return <div>{children}</div>;
    }
}

export default withStyles(styles)(DrcGlobalStyle);
